<template>
  <b-card-code>
    <div v-if="$can('read','4th subject')" class="custom-search d-flex mb-2">
      <router-link :to="{name:'student.fourth.subject'}">
        <b-button variant="outline-primary">
          {{ $t('List') }}
        </b-button>
      </router-link>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <b-row>
        <b-col md="3">
          <b-card-text>
            <span>Select Class </span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="class"
              rules=""
          >
            <select
                name="classes_id"
                v-model="classes_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(cls, key) in classes"
                  :value="cls.id"
                  :key="key"
              >
                {{ cls.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Version</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="version"
              rules=""
          >
            <select
                name="version_id"
                v-model="version_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(version, key) in versions"
                  :value="version.id"
                  :key="key"
              >
                {{ version.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Shift</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="shift"
              rules=""
          >
            <select
                name="shift_id"
                v-model="shift_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(shift, key) in shifts"
                  :value="shift.id"
                  :key="key"
              >
                {{ shift.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Student Group</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="student group"
              rules=""
          >
            <select
                name="student_group_id"
                v-model="student_group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(sgrp, key) in student_groups"
                  :value="sgrp.id"
                  :key="key"
              >
                {{ sgrp.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Institution</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="wing"
              rules=""
          >
            <select
                name="wing_id"
                v-model="wing_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(wing, key) in wings"
                  :value="wing.id"
                  :key="key"
              >
                {{ wing.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Academic Year</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="academic year"
              rules=""
          >
            <select
                name="academic_year_id"
                v-model="academic_year_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(aYear, key) in academic_years"
                  :value="aYear.id"
                  :key="key"
              >
                {{ aYear.year }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Fourth Subject</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="subject_id"
              rules="required"
          >
            <select
                name="subject_id"
                v-model="subject_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(subject, key) in subjects"
                  :value="subject.id"
                  :key="key"
              >
                {{ subject.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="students"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'student_id'">
           {{ cadetIdByID(props.row.id) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{ studentNameByID(props.row.id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <b-form-checkbox v-model="selectedStudent" name="selectedStudent[]" :value="props.row.id"></b-form-checkbox>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-button v-if="selectedStudent.length >0" variant="outline-primary" @click="assign">
      {{ $t('Submit') }}
    </b-button>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
    BCol,BCardText,BRow,BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, } from 'vee-validate'

import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
export default {
  name:'AssignStudentFourthSubject',
  components: {
    BCardCode,
    VueGoodTable,
    BCol,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,BFormCheckbox,
    BFormSelect,BRow,
    BButton,ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Student No',
          field: 'student_id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      classes_id:null,
      version_id:null,
      student_group_id:null,
      subject_id:null,
      academic_year_id:null,
      wing_id:null,
      shift_id:null,
      selectedStudent:[],
    }
  },
  methods:{
    async assign(){
      let data =new FormData();
      data.append('classes_id',this.classes_id);
      data.append('version_id',this.version_id);
      data.append('shift_id',this.shift_id);
      data.append('wing_id',this.wing_id);
      data.append('student_group_id',this.student_group_id);
      data.append('academic_year_id',this.academic_year_id);
      data.append('subject_id',this.subject_id);
      for (var i = 0; i < this.selectedStudent.length; i++) {
        data.append('selectedStudent[]', this.selectedStudent[i]);
      }
      await apiCall.post('/assign/student/fourth/subject',data).then((response) => {
        if (response.data.status === 'success') {
          this.$toaster.success(response.data.message);
          this.selectedStudent=[];
          this.$store.dispatch('GET_ALL_FORTH_SUBJECT');
        } else {
          this.$toaster.error(response.data.message);
        }
      }).catch((error) => {
        if (error.response.status == 422) {
          Object.keys(error.response.data.errors).map((field) => {
            this.$toaster.error(error.response.data.errors[field][0]);
          })
        } else this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    subjects(){
      return this.$store.getters.subjects.filter(
          item =>item.is_optional===1 && item.classes_id===this.classes_id &&
          item.version_id===this.version_id && item.student_group_id===this.student_group_id
      );
    },
    students(){
      return this.$store.getters.students.filter(
          item=>item.classes_id===this.classes_id &&
          item.version_id===this.version_id && item.student_group_id===this.student_group_id  &&
          item.wing_id===this.wing_id && item.shift_id===this.shift_id && item.academic_year_id===this.academic_year_id
      );
    },
    ...mapGetters(['classes','versions','student_groups','studentNameByID','academic_yearByID',
    'academic_years','wings','shifts', 'cadetIdByID']),
  },
  created() {
    this.$store.dispatch('GET_ALL_SUBJECT');
    this.$store.dispatch('GET_ALL_STUDENT');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.shifts.length <1) this.$store.dispatch('GET_ALL_SHIFT');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
